var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-aftersale-upload-img" },
    [
      _vm.countArr.length
        ? _c(
            "div",
            { staticClass: "count-box" },
            _vm._l(_vm.countArr, function(item, index) {
              return _c("div", { key: index, staticClass: "count-item" }, [
                item.tagId == 50
                  ? _c("div", { staticClass: "warning-name" }, [
                      _vm._v("无法读取数据")
                    ])
                  : _vm._e(),
                item.tagId == 51
                  ? _c("div", { staticClass: "warning-name" }, [
                      _vm._v("气管破损")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v("总数: " + _vm._s(item.totalSize))
                ]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v("已验收: " + _vm._s(item.checkedSize))
                ]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v("剩余: "),
                  _c("span", [
                    _vm._v(_vm._s(item.totalSize - item.checkedSize))
                  ])
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "list-box" },
        [
          _vm._m(0),
          _vm._l(_vm.list, function(item, index) {
            return _c("div", { key: index, staticClass: "list-item" }, [
              item.tagId == 50
                ? _c("div", { staticClass: "left" }, [_vm._v("无法读取数据")])
                : _vm._e(),
              item.tagId == 51
                ? _c("div", { staticClass: "left" }, [_vm._v("气管破损")])
                : _vm._e(),
              _c("div", { staticClass: "center" }, [
                _vm._v(_vm._s(item.size) + "个")
              ]),
              _c("div", { staticClass: "right" }, [_vm._v(_vm._s(item.time))])
            ])
          }),
          _c(
            "div",
            { staticClass: "loading-box" },
            [
              _vm.loading && !_vm.isShowAll
                ? _c("Button", {
                    attrs: {
                      loading: "",
                      type: "default",
                      "loading-text": "加载中..."
                    }
                  })
                : _vm._e(),
              !_vm.loading && !_vm.isShowAll
                ? _c(
                    "Button",
                    { attrs: { type: "default" }, on: { click: _vm.loadMore } },
                    [_vm._v("点击加载更多")]
                  )
                : _vm._e(),
              _vm.isShowAll
                ? _c("Button", { attrs: { type: "default" } }, [
                    _vm._v(_vm._s(_vm.list.length ? "已全部显示" : "暂无数据"))
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "create-btn",
          on: {
            click: function($event) {
              _vm.showModal = true
            }
          }
        },
        [_c("div", [_vm._v("新建")]), _c("div", [_vm._v("验收")])]
      ),
      _c(
        "Popup",
        {
          on: { close: _vm.initData },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "modal-box" }, [
            _vm.step == 1
              ? _c("div", { staticClass: "modal-header" }, [
                  _vm._v("请选择核对的故障类型")
                ])
              : _vm._e(),
            _vm.step == 2
              ? _c("div", { staticClass: "modal-header" }, [
                  _vm._v("请输入此次验收的数量")
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm.step == 1
                  ? _c(
                      "div",
                      {
                        class: { "select-item": true, active: _vm.tagId == 50 },
                        on: {
                          click: function($event) {
                            _vm.tagId = 50
                          }
                        }
                      },
                      [_vm._v("无法读取数据")]
                    )
                  : _vm._e(),
                _vm.step == 1
                  ? _c(
                      "div",
                      {
                        class: { "select-item": true, active: _vm.tagId == 51 },
                        on: {
                          click: function($event) {
                            _vm.tagId = 51
                          }
                        }
                      },
                      [_vm._v("气管破损")]
                    )
                  : _vm._e(),
                _vm.step == 2
                  ? _c("Field", {
                      attrs: {
                        type: "number",
                        "input-align": "center",
                        placeholder: "请输入数量"
                      },
                      model: {
                        value: _vm.checkNum,
                        callback: function($$v) {
                          _vm.checkNum = $$v
                        },
                        expression: "checkNum"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.step == 1
                ? _c(
                    "div",
                    { staticClass: "btn btn-next", on: { click: _vm.next } },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-prev",
                      on: {
                        click: function($event) {
                          _vm.step = 1
                        }
                      }
                    },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "div",
                    {
                      class: { btn: true, "btn-sure": true, pass: _vm.isPass },
                      on: { click: _vm.postNum }
                    },
                    [_vm._v("确认")]
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "left" }, [_vm._v("故障类型")]),
      _c("div", { staticClass: "center" }, [_vm._v("数量")]),
      _c("div", { staticClass: "right" }, [_vm._v("验收日期")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }